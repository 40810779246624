.prelaunch_carousel__label {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
}

.prelaunch_carousel__label .h-1-wrapper {
    border: 0px none;
    height: 70px;
    width: 312px;
}

.prelaunch_carousel__label .text-wrapper {
    color: #004aad;
    font-family: "Montserrat Alternates-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    left: 0;
    letter-spacing: -0.32px;
    line-height: 37.1px;
    text-align: center;
    top: 0;
    width: 312px;
}

.prelaunch_carousel__label_secundario {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 51px;
}

.prelaunch_carousel__label_secundario .selecciona-tu-wrapper {
    border: 0px none;
    height: 52px;
    width: 281px;
}

.prelaunch_carousel__label_secundario .selecciona-tu {
    color: #333538;
    font-family: "Montserrat Alternates-SemiBold", Helvetica;
    font-size: 16px;
    font-weight: 600;
    left: 0;
    letter-spacing: -0.16px;
    line-height: 18.6px;
    text-align: center;
    top: 0;
    width: 281px;
}






/* CAROUSEL*/

.prelaunch_carousel__ {
    height: 437px;
    position: relative;
    width: 312px;
    margin: auto;
}

.prelaunch_carousel__ .group {
    height: 437px;
    left: 36px;
    position: absolute;
    top: 0;
    width: 242px;
}

.prelaunch_carousel__ .overlap {
    border-radius: 16px;
    height: 389px;
    left: 0;
    position: absolute;
    top: 0;
    width: 240px;
}

.prelaunch_carousel__ .rectangle-wrapper {
    background-color: #ffffff;
    border: 0.2px solid;
    border-color: #dde1e7;
    border-radius: 16px;
    box-shadow: 2px 2px 8px 2px #3b465140;
    height: 389px;
    left: 0;
    position: absolute;
    top: 0;
    width: 240px;
}

.prelaunch_carousel__ .rectangle {
    height: 256px;
    left: 0px;
    object-fit: cover;
    position: absolute;
    top: -7px;
    width: 262px;
}

.prelaunch_carousel__ .overlap-group-wrapper {
    height: 36px;
    left: 36px;
    position: absolute;
    top: 316px;
    width: 179px;
}

.prelaunch_carousel__ .overlap-group {
    background-color: #004aad;
    border-radius: 24px;
    height: 36px;
    position: relative;
    width: 175px;
}

.prelaunch_carousel__ .quiero-ir {
    color: #004aad;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 600;
    left: 20px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 8px;
    width: 135px;
}

.prelaunch_carousel__ .text-wrapper {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 20px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 8px;
    width: 135px;
}

.prelaunch_carousel__ .div {
    background-color: #ffffff;
    border: 0.2px solid;
    border-color: #dde1e7;
    border-radius: 16px;
    box-shadow: 2px 2px 8px 2px #3b465140;
    height: 389px;
    left: 0;
    position: absolute;
    top: 0;
    width: 240px;
}

.prelaunch_carousel__ .img {
    height: 256px;
    left: 0px;
    object-fit: cover;
    position: absolute;
    top: -7px;
    width: 262px;
}

.prelaunch_carousel__ .lima-arequipa {
    color: #333538;
    font-family: "Montserrat Alternates", Helvetica;
    font-size: 20px;
    font-weight: 600;
    height: 30px;
    left: 37px;
    letter-spacing: -0.2px;
    line-height: 23.2px;
    position: absolute;
    text-align: center;
    top: 266px;
    width: 170px;
}

.prelaunch_carousel__ .group-2 {
    height: 8px;
    left: 42px;
    position: absolute;
    top: 429px;
    width: 160px;
}

.prelaunch_carousel__ .rectangle-2 {
    border-radius: 4px;
    height: 8px;
    left: 0;
    position: absolute;
    top: 0;
    width: 41px;
}

.prelaunch_carousel__ .rectangle-3 {
    border-radius: 4px;
    height: 8px;
    left: 60px;
    position: absolute;
    top: 0;
    width: 41px;
}

.prelaunch_carousel__ .rectangle-4 {
    border-radius: 4px;
    height: 8px;
    left: 119px;
    position: absolute;
    top: 0;
    width: 41px;
}

.prelaunch_carousel__ .next-wrapper {
    background-color: #004aad;
    border-radius: 4px;
    height: 20px;
    left: 292px;
    position: absolute;
    top: 195px;
    width: 20px;
}

.prelaunch_carousel__ .next {
    height: 13px;
    left: 7px;
    position: absolute;
    top: 3px;
    width: 9px;
}

.prelaunch_carousel__ .next-2 {
    height: 13px;
    left: 1px;
    position: absolute;
    top: 198px;
    width: 9px;
}

.prelaunch_carousel__ .img-wrapper {
    background-color: #004aad;
    border-radius: 4px;
    height: 20px;
    left: 0;
    position: absolute;
    top: 195px;
    transform: rotate(180deg);
    width: 20px;
}

.prelaunch_carousel__ .next-3 {
    height: 13px;
    left: 7px;
    position: absolute;
    top: 3px;
    transform: rotate(-180deg);
    width: 9px;
}

.prelaunch_carousel__ .property-1-0-default {
    background-color: #004aad;
}

.prelaunch_carousel__ .property-1-0-variant-3 {
    background-color: #dde1e7;
}

.prelaunch_carousel__ .property-1-0-variant-2 {
    background-color: #dde1e7;
}

.prelaunch_carousel__ .property-1-1-default {
    background-color: #dde1e7;
}

.prelaunch_carousel__ .property-1-1-variant-3 {
    background-color: #dde1e7;
}

.prelaunch_carousel__ .property-1-1-variant-2 {
    background-color: #004aad;
}

.prelaunch_carousel__ .property-1-2-default {
    background-color: #dde1e7;
}

.prelaunch_carousel__ .property-1-2-variant-3 {
    background-color: #004aad;
}

.prelaunch_carousel__ .property-1-2-variant-2 {
    background-color: #dde1e7;
}