.prelaunch_cintillo {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow-x: scroll;
    max-width: 834px;
    margin: auto;
}


@media(min-width: 1440px) {

    .prelaunch_cintillo {
        max-width: 1440px;
    }

}