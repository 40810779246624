.container_buy_tickets {
    background-color: white;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 12px;
    padding: 24px;
    box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 420px;
    left: 0;
}

.container_buy_tickets p {
    font-size: 0.7rem;
}

.container_buy_tickets input {
    font-size: 16px;
}

.container_butacas_tickets {
    margin: auto;
    width: 100%;
    border-radius: 20px;
}

.container_butacas_tickets_selections {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 12px;
    padding: 24px;
    box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
    display: flex;
    width: 100%;
    position: relative;
    height: fit-content;

}

.btn_buy_tickets {
    font-size: 12px;
}



.circle_leyenda {
    width: fit-content;
    padding: 4px 6px;
    border-radius: 5px;
    margin: 0;
}

.container_butacas_tickets_spaces {}

.container_butacas_tickets_spaces_grid {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
}

.container_butacas_tickets_spaces_grid .ticket {
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    text-align: center;
    padding: 2px 3px;
    cursor: pointer;
}

.ticket_modal_badget {
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    text-align: center;
    padding: 2px 9px;
    cursor: pointer;
}

.btn_buy_tickets {
    background-color: #e31837;
    color: white;
    font-weight: 600;
    letter-spacing: 2px;
}

.btn_buy_tickets:hover {
    background-color: #e72f4b;
}

@media(min-width: 650px) {
    .container_buy_tickets {
        background-color: white;
        border: 1px solid rgb(221, 221, 221);
        border-radius: 12px;
        padding: 24px;
        box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
        display: flex;
        width: 100%;
        max-width: 555px;
        position: relative;
        height: fit-content;

    }

    .btn_buy_tickets {
        font-size: 16px;
    }

    .container_butacas_tickets {
        margin: auto;
        width: 100%;
        padding: 20px;
        border-radius: 20px;
    }

    .container_butacas_tickets_spaces_grid {
        grid-column-gap: 10px;
        grid-row-gap: 10px;
    }

    .container_buy_tickets p {
        font-size: 1rem;
    }
}