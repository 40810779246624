.frame {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: center;
    position: relative;
}

.frame .suscr-bete {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    width: fit-content;
}

.frame.variant-8 {
    background-color: #f36733;
    border-radius: 32px;
    height: 54px;
    padding: 10px 32px;
    width: 192px;
}

.frame.variant-4 {
    background-color: #f36733;
    border-radius: 24px;
    height: 40px;
    padding: 10px 32px;
    width: fit-content;
}

.frame.variant-2 {
    background-color: #004aad;
    border-radius: 24px;
    height: 44px;
    padding: 10px 24px;
    width: fit-content;
}

.frame.variant-3 {
    background-color: #004aad;
    border-radius: 24px;
    height: 40px;
    padding: 10px 32px;
    width: fit-content;
}

.frame.variant-5 {
    background-color: #004aad;
    border-radius: 32px;
    height: 54px;
    padding: 10px 32px;
    width: 241px;
}

.frame.variant-6 {
    background-color: #f36733;
    border-radius: 32px;
    height: 54px;
    padding: 10px 32px;
    width: 241px;
}

.frame.variant-7 {
    background-color: #004aad;
    border-radius: 32px;
    height: 54px;
    padding: 10px 32px;
    width: 192px;
}

.frame.default {
    background-color: #f36733;
    border-radius: 24px;
    height: 44px;
    padding: 10px 24px;
    width: fit-content;
}

.frame.variant-8 .suscr-bete {
    font-size: 22px;
}

.frame.variant-4 .suscr-bete {
    font-size: 16px;
    margin-top: -0.5px;
    white-space: nowrap;
}

.frame.variant-2 .suscr-bete {
    font-size: 16px;
    white-space: nowrap;
}

.frame.variant-3 .suscr-bete {
    font-size: 16px;
    margin-top: -0.5px;
    white-space: nowrap;
}

.frame.variant-5 .suscr-bete {
    font-size: 22px;
}

.frame.variant-6 .suscr-bete {
    font-size: 22px;
}

.frame.variant-7 .suscr-bete {
    font-size: 22px;
}

.frame.default .suscr-bete {
    font-size: 16px;
    white-space: nowrap;
}