@media(max-width: 768px) {

    .newsletter {
        background-color: transparent;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-top: 68px;
    }

    .newsletter .mobile-whoiam-wrapper {
        border: 0px none;
        width: 360px;
    }

    .newsletter .mobile-whoiam-suscribirte {}


    .mobile-whoiam {
        height: 968px;
        position: relative;
        width: 360px;
    }

    .mobile-whoiam .overlap-group {
        height: 821px;
        left: 0;
        position: absolute;
        top: 206px;
        width: 360px;
    }

    .mobile-whoiam .rectangle {
        height: 412px;
        left: 0;
        position: absolute;
        top: 0;
        width: 360px;
    }

    .mobile-whoiam .div {
        background-color: #004AAD;
        border-radius: 32px 32px 0px 0px;
        height: 380px;
        left: 0;
        position: absolute;
        top: 384px;
        width: 360px;
    }

    .mobile-whoiam .rectangle-2 {
        border: 1px solid;
        border-color: #fff;
        border-radius: 24px;
        height: 54px;
        left: 49px;
        position: absolute;
        top: 621px;
        width: 262px;
    }

    .mobile-whoiam .rectangle-3 {
        background-color: #FFC500;
        border-radius: 24px;
        height: 54px;
        left: 49px;
        position: absolute;
        top: 620px;
        width: 262px;
    }

    .mobile-whoiam .suscribirme {
        color: #333538;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 22px;
        font-weight: 500;
        height: 23px;
        left: 101px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 635px;
        white-space: nowrap;
        width: 158px;
    }

    .mobile-whoiam .d-janos-tu-correo {
        color: #fff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 16px;
        font-weight: 400;
        height: 22px;
        left: 58px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 636px;
        width: 243px;
    }

    .mobile-whoiam .est-s-listo-para-tu {
        color: #fff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 28px;
        font-weight: 700;
        left: 24px;
        letter-spacing: -0.28px;
        line-height: 32.5px;
        position: absolute;
        text-align: center;
        top: 452px;
        width: 312px;
    }

    .mobile-whoiam .suscr-bete-y-recibe {
        color: #fff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 16px;
        font-weight: 400;
        left: 39px;
        letter-spacing: -0.16px;
        line-height: 18.6px;
        position: absolute;
        text-align: center;
        top: 550px;
        width: 281px;
    }

    .mobile-whoiam .text-wrapper {
        color: #ffffff;
    }

    .mobile-whoiam .span {
        color: #ffffff;
        font-weight: 600;
    }

    .mobile-whoiam .vector {
        height: 15px;
        left: 38px;
        position: absolute;
        top: 428px;
        width: 14px;
    }

    .mobile-whoiam .img {
        height: 15px;
        left: 300px;
        position: absolute;
        top: 497px;
        width: 14px;
    }

    .mobile-whoiam .mask-group {
        height: 149px;
        left: 275px;
        position: absolute;
        top: 304px;
        width: 85px;
    }

    .mobile-whoiam .adobestock-wrapper {
        height: 183px;
        left: 0;
        position: absolute;
        top: 17px;
        transform: rotate(180deg);
        width: 210px;
    }

    .mobile-whoiam .adobestock {
        height: 183px;
        left: 0;
        position: absolute;
        top: 0;
        transform: rotate(-180deg);
        width: 210px;
    }

    .mobile-whoiam .suerte-viajera-es {
        color: #333538;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 16px;
        font-weight: 400;
        left: 24px;
        letter-spacing: -0.16px;
        line-height: 18.6px;
        position: absolute;
        text-align: center;
        top: 77px;
        width: 312px;
    }

    .mobile-whoiam .text-wrapper-2 {
        color: #333538;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.16px;
        line-height: 18.6px;
    }

    .mobile-whoiam .text-wrapper-3 {
        font-weight: 600;
    }

    .mobile-whoiam .overlap {
        height: 49px;
        left: 24px;
        position: absolute;
        top: -1px;
        width: 312px;
    }

    .mobile-whoiam .vector-2 {
        height: 19px;
        left: 176px;
        position: absolute;
        top: 30px;
        width: 119px;
    }

    .mobile-whoiam .qui-nes-somos {
        color: #F36733;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 32px;
        font-weight: 700;
        left: 0;
        letter-spacing: -0.32px;
        line-height: 37.1px;
        position: absolute;
        text-align: center;
        top: 0;
        white-space: nowrap;
        width: 312px;
    }


}

@media(min-width: 768px) {

    .tablet-whoiam-container {
        margin-top: 40px;
    }

    .tablet-whoaiam {
        height: 1791px;
        position: relative;
        width: 838px;
        margin: auto;
    }

    .tablet-whoaiam .overlap {
        height: 1497px;
        left: 0;
        position: absolute;
        top: 294px;
        width: 838px;
    }

    .tablet-whoaiam .group {
        height: 838px;
        left: 0;
        position: absolute;
        top: 0;
        width: 838px;
    }

    .tablet-whoaiam .overlap-group {
        background-image: url(https://generation-sessions.s3.amazonaws.com/fb7056e21a632820dcc619e78a3b5281/img/rectangle-1491-1.png);
        background-position: 50% 50%;
        background-size: cover;
        height: 838px;
        left: 2px;
        position: relative;
        width: 834px;
    }

    .tablet-whoaiam .mask-group {
        height: 271px;
        position: relative;
        top: 35px;
        transform: rotate(180deg);
        width: 311px;
    }

    .tablet-whoaiam .adobestock {
        height: 271px;
        left: 0;
        position: absolute;
        top: 0;
        transform: rotate(-180deg);
        width: 311px;
    }

    .tablet-whoaiam .rectangle {
        background-color: #004AAD;
        border-radius: 40px 40px 0px 0px;
        height: 716px;
        left: 2px;
        position: absolute;
        top: 781px;
        width: 834px;
    }

    .tablet-whoaiam .div {
        border: 1px solid;
        border-color: #fff;
        border-radius: 32px;
        height: 64px;
        left: 229px;
        position: absolute;
        top: 1224px;
        width: 380px;
    }

    .tablet-whoaiam .overlap-wrapper {
        height: 64px;
        left: 229px;
        position: absolute;
        top: 1334px;
        width: 382px;
    }

    .tablet-whoaiam .suscribirme-wrapper {
        background-color: #ffc500;
        border-radius: 32px;
        height: 64px;
        position: relative;
        width: 380px;
        cursor: pointer;
    }

    .tablet-whoaiam .suscribirme {
        color: #333538;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 24px;
        font-weight: 500;
        height: 44px;
        left: 75px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 12px;
        width: 230px;
    }

    .tablet-whoaiam .d-janos-tu-correo {
        color: #fff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 20px;
        font-weight: 400;
        height: 46px;
        left: 242px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 1242px;
        width: 353px;
    }

    .tablet-whoaiam .est-s-listo-para-tu {
        color: #fff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 40px;
        font-weight: 700;
        left: 145px;
        letter-spacing: -0.4px;
        line-height: 46.4px;
        position: absolute;
        text-align: center;
        top: 922px;
        width: 548px;
    }

    .tablet-whoaiam .suscr-bete-y-recibe {
        color: #fff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 24px;
        font-weight: 400;
        left: 132px;
        letter-spacing: -0.24px;
        line-height: 27.8px;
        position: absolute;
        text-align: center;
        top: 1080px;
        width: 572px;
    }

    .tablet-whoaiam .text-wrapper {
        color: #ffffff;
    }

    .tablet-whoaiam .span {
        color: #ffffff;
        font-weight: 600;
    }

    .tablet-whoaiam .vector {
        height: 29px;
        left: 190px;
        position: absolute;
        top: 895px;
        width: 27px;
    }

    .tablet-whoaiam .img {
        height: 29px;
        left: 609px;
        position: absolute;
        top: 991px;
        width: 27px;
    }

    .tablet-whoaiam .mask-group-2 {
        height: 303px;
        left: 665px;
        position: absolute;
        top: 620px;
        width: 171px;
    }

    .tablet-whoaiam .suerte-viajera-es {
        color: #333538;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 24px;
        font-weight: 400;
        left: 92px;
        letter-spacing: -0.24px;
        line-height: 27.8px;
        position: absolute;
        text-align: center;
        top: 114px;
        width: 636px;
    }

    .tablet-whoaiam .text-wrapper-2 {
        color: #333538;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: -0.24px;
        line-height: 27.8px;
    }

    .tablet-whoaiam .text-wrapper-3 {
        font-weight: 700;
    }

    .tablet-whoaiam .overlap-2 {
        height: 78px;
        left: 97px;
        position: absolute;
        top: -1px;
        width: 635px;
    }

    .tablet-whoaiam .vector-2 {
        height: 24px;
        left: 343px;
        position: absolute;
        top: 52px;
        width: 152px;
    }

    .tablet-whoaiam .qui-nes-somos {
        color: #F36733;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 40px;
        font-weight: 700;
        height: 78px;
        left: 0;
        letter-spacing: -0.4px;
        line-height: 46.4px;
        position: absolute;
        text-align: center;
        top: 0;
        width: 635px;
    }


}



@media(min-width: 1440px) {

    .desktop-whoiam {
        height: 1180px;
        width: 1440px;
        margin: auto;
    }

    .desktop-whoiam .overlap {
        height: 1180px;
        position: relative;
    }

    .desktop-whoiam .mask-group {
        height: 730px;
        left: 0;
        position: absolute;
        top: 0;
        width: 1440px;
    }

    .desktop-whoiam .rectangle {
        background-color: #fff;
        border-radius: 32px;
        height: 383px;
        left: 122px;
        opacity: 0.8;
        position: absolute;
        top: 103px;
        width: 588px;
    }

    .desktop-whoiam .div {
        background-color: #004AAD;
        border-radius: 32px 32px 0px 0px;
        height: 487px;
        left: 0;
        position: absolute;
        top: 693px;
        width: 1440px;
    }

    .desktop-whoiam .suscr-bete-y-recibe {
        color: #fff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 24px;
        font-weight: 400;
        height: 46px;
        left: 243px;
        letter-spacing: -0.24px;
        line-height: 27.8px;
        position: absolute;
        text-align: center;
        top: 909px;
        width: 954px;
    }

    .desktop-whoiam .text-wrapper {
        color: #ffffff;
    }

    .desktop-whoiam .span {
        color: #ffffff;
        font-weight: 600;
    }

    .desktop-whoiam .vector {
        height: 26px;
        left: 450px;
        position: absolute;
        top: 210px;
        width: 179px;
    }

    .desktop-whoiam .img {
        height: 279px;
        left: 1281px;
        position: absolute;
        top: 530px;
        width: 159px;
    }

    .desktop-whoiam .est-s-listo-para-tu {
        color: #fff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 48px;
        font-weight: 700;
        height: 69px;
        left: 280px;
        letter-spacing: -0.48px;
        line-height: 55.7px;
        position: absolute;
        text-align: center;
        top: 800px;
        width: 879px;
    }

    .desktop-whoiam .vector-2 {
        height: 35px;
        left: 241px;
        position: absolute;
        top: 819px;
        width: 32px;
    }

    .desktop-whoiam .vector-3 {
        height: 35px;
        left: 1168px;
        position: absolute;
        top: 819px;
        width: 32px;
    }

    .desktop-whoiam .group {
        height: 64px;
        left: 300px;
        position: absolute;
        top: 1010px;
        width: 844px;
    }

    .desktop-whoiam .overlap-group {
        border: 1px solid;
        border-color: #ffffff;
        border-radius: 32px;
        height: 64px;
        left: 0;
        position: absolute;
        top: 0;
        width: 582px;
    }

    .desktop-whoiam .d-janos-tu-correo {
        color: #fff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 22px;
        font-weight: 500;
        height: 30px;
        left: 118px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 16px;
        width: 335px;
    }

    .desktop-whoiam .suscribirme-wrapper {
        background-color: #ffc500;
        border-radius: 32px;
        height: 64px;
        left: 315px;
        position: absolute;
        top: 0;
        width: 234px;
        cursor: pointer;
    }

    .desktop-whoiam .suscribirme {
        color: #333538;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 28px;
        font-weight: 500;
        height: 31px;
        left: 30px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 16px;
        white-space: nowrap;
        width: 173px;
    }

    .desktop-whoiam .suerte-viajera-es {
        color: #333538;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 24px;
        font-weight: 500;
        left: 164px;
        letter-spacing: -0.24px;
        line-height: 27.8px;
        position: absolute;
        text-align: center;
        top: 273px;
        width: 504px;
    }

    .desktop-whoiam .qui-nes-somos {
        color: #F36733;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 56px;
        font-weight: 700;
        height: 38px;
        left: 142px;
        letter-spacing: -0.56px;
        line-height: 65px;
        position: absolute;
        text-align: center;
        top: 173px;
        white-space: nowrap;
        width: 548px;
    }



}