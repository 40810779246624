.countdown-values {
    display: flex;
    background-color: #e31837;
    color: white;
    padding: 3px;
    border-radius: 10px;
}

.big-text {
    font-weight: bold;
    font-size: 0.9rem;
    line-height: 1;
    margin: 5px;
}

.countdown-value {
    margin: 2px 3px;
    text-align: center;
}

.countdown-value span {
    font-size: 0.7rem;
}

@media(min-width: 650px) {

    .countdown-values {
        padding: 6px;
    }

    .big-text {
        font-size: 1.4rem;
    }

    .countdown-value span {
        font-size: 1.1rem;
    }
}