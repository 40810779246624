.container_carousel_info {
    display: flex;
    max-height: 600px;
}

.container_carousel_info_active {
    width: 60%;
    padding: 8px;
    object-fit: contain;
    max-height: 600px;
}

.container_carousel_info_others {
    width: 40%;
    padding: 8px;
    max-height: 600px;
    overflow-y: scroll;
}

.container_carousel_info_others img {
    width: 100%;
    padding: 8px;
    height: 210px;
}

.subheader_pdp {
    position: sticky;
    top: 50px;
    background: #fffde7;
    z-index: 10;
    display: flex;
    justify-content: space-between;
}


.date_sorteo {
    border: 1px solid #fff;
    background: #ff6565;
    border-radius: 14px;
    color: white;
    justify-self: end;
}


@media(min-width: 650px) {


    .txt_title_vuelo {
        font-size: 30px !important;
    }
}