@media(max-width: 768px) {
    .prelaunch_footer {
        width: 100%;
        background-color: #000000;
    }

    .prelaunch_footer .mobile-footer {
        background-color: #000000;
        height: 259px;
        position: relative;
        width: 360px;
        margin: auto;
    }

    .prelaunch_footer .mobile-footer .logo-suerte-viajera {
        height: 62px;
        left: 83px;
        object-fit: cover;
        position: absolute;
        top: 176px;
        width: 194px;
    }

    .prelaunch_footer .mobile-footer .ic-baseline-facebook {
        height: 24px;
        left: 188px;
        position: absolute;
        top: 106px;
        width: 24px;
    }

    .prelaunch_footer .mobile-footer .mdi-instagram {
        height: 24px;
        left: 108px;
        position: absolute;
        top: 106px;
        width: 24px;
    }

    .prelaunch_footer .mobile-footer .mdi-youtube {
        height: 24px;
        left: 148px;
        position: absolute;
        top: 106px;
        width: 24px;
    }

    .prelaunch_footer .mobile-footer .ic-baseline-tiktok {
        height: 24px;
        left: 228px;
        position: absolute;
        top: 106px;
        width: 24px;
    }

    .prelaunch_footer .mobile-footer .redes-sociales {
        color: #fff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 16px;
        font-weight: 600;
        height: 22px;
        left: 85px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 67px;
        width: 189px;
    }

}


@media(min-width: 768px) {

    .prelaunch_footer {
        width: 100%;
        background-color: #000000;
    }

    .tablet-footer {
        background-color: #000000;
        height: 560px;
        position: relative;
        width: 836px;
        margin: auto;
    }

    .tablet-footer .logo-suerte-viajera {
        height: 151px;
        left: 181px;
        object-fit: cover;
        position: absolute;
        top: 332px;
        width: 472px;
    }

    .tablet-footer .ic-baseline-facebook {
        height: 41px;
        left: 430px;
        position: absolute;
        top: 228px;
        width: 41px;
    }

    .tablet-footer .mdi-instagram {
        height: 41px;
        left: 295px;
        position: absolute;
        top: 228px;
        width: 41px;
    }

    .tablet-footer .mdi-youtube {
        height: 41px;
        left: 363px;
        position: absolute;
        top: 228px;
        width: 41px;
    }

    .tablet-footer .ic-baseline-tiktok {
        height: 41px;
        left: 498px;
        position: absolute;
        top: 228px;
        width: 41px;
    }

    .tablet-footer .redes-sociales {
        color: #fff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 24px;
        font-weight: 500;
        height: 51px;
        left: 195px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 144px;
        width: 444px;
    }



}

@media(min-width: 1440px) {
    .desktop-footer {
        background-color: #000000;
        height: 246px;
        position: relative;
        width: 1440px;
        margin: auto;
    }

    .desktop-footer .logo-suerte-viajera {
        height: 96px;
        left: 122px;
        object-fit: cover;
        position: absolute;
        top: 86px;
        width: 298px;
    }

    .desktop-footer .ic-baseline-facebook {
        height: 38px;
        left: 1220px;
        position: absolute;
        top: 116px;
        width: 38px;
    }

    .desktop-footer .mdi-instagram {
        height: 38px;
        left: 1095px;
        position: absolute;
        top: 116px;
        width: 38px;
    }

    .desktop-footer .mdi-youtube {
        height: 38px;
        left: 1158px;
        position: absolute;
        top: 116px;
        width: 38px;
    }

    .desktop-footer .ic-baseline-tiktok {
        height: 38px;
        left: 1283px;
        position: absolute;
        top: 116px;
        width: 38px;
    }
}