.prelaunch_header {
    height: 90px;
    position: relative;
    width: 100%;
}

.prelaunch_header .overlap {
    background-color: #fff;
    height: 90px;
    left: 0;
    position: relative;
    top: 0;
    width: 320px;
    margin: auto;
}

.prelaunch_header .group {
    height: 36px;
    right: 0px;
    position: absolute;
    top: 29px;
    width: 122px;
}

.prelaunch_header .overlap-group {
    background-color: #f36733;
    border-radius: 24px;
    height: 36px;
    position: relative;
    width: 120px;
}

.prelaunch_header .suscribete {
    color: #ffffff;
    font-family: "Inter-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 10px;
    white-space: nowrap;
    width: 120px;
    cursor: pointer;
}

.prelaunch_header .logo-suerte-viajera {
    height: 50px;
    left: 0px;
    object-fit: cover;
    position: absolute;
    top: 25px;
    width: 152px;
}

.prelaunch_header .vector {
    height: 1px;
    left: 1px;
    object-fit: cover;
    position: absolute;
    top: 90px;
    width: 100%;
}

@media(min-width: 768px) {
    .prelaunch_header .overlap {
        width: 620px;
    }
}

@media(min-width: 1024px) {
    .prelaunch_header .overlap {
        width: 1024px;
    }
}