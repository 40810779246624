.container_terms {
    margin-top: 70px;
    margin-bottom: 70px;
    padding-left: 40px;
    padding-right: 40px;
    min-height: 80vh;
}

.container_terms h3 {
    font-weight: 700;
    font-size: 32px;
    margin-top: 10px;
}

.container_terms p {
    font-weight: 400;
    font-size: 16px;
}

@media(min-width: 650px) {

    .container_terms h3 {
        font-size: 52px;
        margin-top: 10px;
    }

    .container_terms p {
        font-size: 18px;
    }
}