@media(max-width: 768px) {

    .prelaunch_hero_spacing {
        padding: 30px 0px;
    }

    .prelaunch_hero {
        height: 651px;
        position: relative;
        width: 303px;
        margin: auto;
    }

    .prelaunch_hero .participa-en {
        color: #333538;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 20px;
        font-weight: 400;
        left: 5px;
        letter-spacing: -0.2px;
        line-height: 23.2px;
        position: absolute;
        top: 176px;
        width: 281px;
    }

    .prelaunch_hero .actualmente-nos {
        color: #333538;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 16px;
        font-weight: 400;
        left: 6px;
        letter-spacing: -0.16px;
        line-height: 18.6px;
        position: absolute;
        text-align: center;
        top: 588px;
        width: 297px;
    }

    .prelaunch_hero .text-wrapper {
        color: #333538;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.16px;
        line-height: 18.6px;
    }

    .prelaunch_hero .span {
        font-weight: 700;
    }

    .prelaunch_hero .overlap-group {
        height: 162px;
        left: 0;
        position: absolute;
        top: 0;
        width: 286px;
    }

    .prelaunch_hero .vector {
        height: 19px;
        left: 8px;
        position: absolute;
        top: 143px;
        width: 119px;
    }

    .prelaunch_hero .tu-momento-de-volar {
        color: #004aad;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 40px;
        font-weight: 700;
        left: 5px;
        letter-spacing: -0.4px;
        line-height: 44px;
        position: absolute;
        top: 19px;
        width: 281px;
    }

    .prelaunch_hero .img {
        height: 21px;
        left: 0;
        position: absolute;
        top: 0;
        width: 20px;
    }

    .prelaunch_hero .vector-2 {
        height: 15px;
        left: 5px;
        position: absolute;
        top: 566px;
        width: 14px;
    }

    .prelaunch_hero .vector-3 {
        height: 15px;
        left: 279px;
        position: absolute;
        top: 637px;
        width: 14px;
    }

    .prelaunch_hero .group {
        height: 270px;
        left: 23px;
        position: absolute;
        top: 276px;
        width: 271px;
    }
}

@media(min-width: 768px) {

    .tablet-hero {
        height: 551px;
        width: 100%;
    }

    .tablet-hero .overlap-group {
        height: 551px;
        position: relative;
        width: 100%;
        max-width: 825px;
        margin: auto;
    }

    .tablet-hero .vector {
        height: 20px;
        left: 74px;
        position: absolute;
        top: 204px;
        width: 137px;
    }

    .tablet-hero .participa-en {
        color: #333538;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 20px;
        font-weight: 400;
        left: 68px;
        letter-spacing: -0.2px;
        line-height: 23.2px;
        position: absolute;
        top: 253px;
        width: 341px;
    }

    .tablet-hero .group {
        height: 342px;
        left: 427px;
        position: absolute;
        top: 35px;
        width: 343px;
    }

    .tablet-hero .tu-momento-de-volar {
        color: #004aad;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 48px;
        font-weight: 700;
        left: 68px;
        letter-spacing: -0.48px;
        line-height: 52.8px;
        position: absolute;
        top: 54px;
        width: 381px;
    }

    .tablet-hero .mask-group {
        height: 109px;
        left: 0;
        position: absolute;
        top: 427px;
        width: 55px;
    }

    .tablet-hero .img {
        height: 18px;
        left: 140px;
        position: absolute;
        top: 446px;
        width: 18px;
    }

    .tablet-hero .vector-2 {
        height: 18px;
        left: 620px;
        position: absolute;
        top: 486px;
        width: 18px;
    }

    .tablet-hero .actualmente-nos {
        color: var(--textos);
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 24px;
        font-weight: 400;
        height: 95px;
        left: 148px;
        letter-spacing: -0.24px;
        line-height: 31.7px;
        position: absolute;
        text-align: center;
        top: 423px;
        width: 542px;
    }

    .tablet-hero .text-wrapper {
        color: #333538;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: -0.24px;
        line-height: 31.7px;
    }

    .tablet-hero .span {
        font-weight: 700;
    }

    .tablet-hero .rectangle {
        border: 0px none;
        height: 551px;
        left: 2px;
        position: absolute;
        top: 0;
        width: 834px;
    }

}



@media(min-width: 1440px) {

    .hero_container_desktop {
        background-color: transparent;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }

    .hero_container_desktop .desktop-hero-wrapper {
        border: 0px none;
        height: 785px;
        position: relative;
        width: 1440px;
    }

    .hero_container_desktop .desktop-hero {}

    .hero_container_desktop .overlap-group {
        height: 154px;
        left: 122px;
        position: absolute;
        top: 117px;
        width: 578px;
    }

    .hero_container_desktop .vector {
        height: 21px;
        left: 372px;
        position: absolute;
        top: 134px;
        width: 158px;
    }

    .hero_container_desktop .text-wrapper {
        color: #004aad;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 64px;
        font-weight: 700;
        left: 0;
        letter-spacing: -0.64px;
        line-height: 70.4px;
        position: absolute;
        top: 0;
        width: 578px;
    }

    .hero_container_desktop .participa-en {
        color: #333538;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 24px;
        font-weight: 400;
        left: 122px;
        letter-spacing: -0.24px;
        line-height: 27.8px;
        position: absolute;
        top: 308px;
        width: 547px;
    }

    .hero_container_desktop .span {
        color: #333538;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: -0.24px;
        line-height: 27.8px;
    }

    .hero_container_desktop .text-wrapper-2 {
        font-weight: 700;
    }

    .hero_container_desktop .group {
        height: 520px;
        left: 796px;
        position: absolute;
        top: 51px;
        width: 522px;
    }

    .hero_container_desktop .img {
        height: 27px;
        left: 277px;
        position: absolute;
        top: 603px;
        width: 27px;
    }

    .hero_container_desktop .vector-2 {
        height: 27px;
        left: 921px;
        position: absolute;
        top: 705px;
        width: 27px;
    }

    .hero_container_desktop .mask-group {
        height: 212px;
        left: 0;
        position: absolute;
        top: 404px;
        width: 122px;
    }

    .hero_container_desktop .actualmente-nos {
        color: var(--textos);
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 32px;
        font-weight: 400;
        height: 41px;
        left: 278px;
        letter-spacing: -0.32px;
        line-height: 42.2px;
        position: absolute;
        text-align: center;
        top: 655px;
        white-space: nowrap;
        width: 884px;
    }

    .hero_container_desktop .text-wrapper-3 {
        color: #333538;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 32px;
        font-weight: 400;
        letter-spacing: -0.32px;
        line-height: 42.2px;
    }


}