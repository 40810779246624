#customBtn {
    display: inline-block;
    background: white;
    color: #444;
    width: 190px;
    border-radius: 5px;
    border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
    margin: auto;
    margin-top: 1rem;
}

#customBtn:hover {
    cursor: pointer;
}

span.label {
    font-family: serif;
    font-weight: normal;
}

span.icon {
    background: url('../../images/google_icon.png') transparent 5px 50% no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
}

span.buttonText {
    display: inline-block;
    vertical-align: middle;
    padding-left: 42px;
    padding-right: 42px;
    font-size: 14px;
    font-weight: bold;
    /* Use the Roboto font that is loaded in the <head> */
    font-family: 'Roboto', sans-serif;
}


.container_btn_login {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.container_sidebar_profile {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: fit-content;
}

.side_bar_img_profile {
    border-radius: 50%;
}

.side_bar_btn_logout {
    background-color: #F2D900;
    border-radius: 30px;
    border: 1px solid #000000;
    cursor: pointer;
}

.side_bar_btn_logout:hover {
    background-color: #e31837;
    border-radius: 30px;
    color: white;
    border: 1px solid #e31837;
}