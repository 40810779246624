@media(max-width: 768px) {

    .mobile-counter {
        height: 258px;
        width: 313px;
        margin: auto;
        margin-top: 57px;
        margin-bottom: 57px;
    }

    .mobile-counter .overlap {
        height: 258px;
        position: relative;
    }

    .mobile-counter .group {
        background-color: #004aad;
        border-radius: 16px;
        height: 226px;
        left: 0;
        position: absolute;
        top: 32px;
        width: 312px;
    }

    .mobile-counter .mask-group {
        height: 62px;
        left: 0;
        position: absolute;
        top: 0;
        width: 56px;
    }

    .mobile-counter .sortearemos-nuestro {
        color: #ffffff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 20px;
        font-weight: 600;
        left: 33px;
        letter-spacing: -0.2px;
        line-height: 23.2px;
        position: absolute;
        text-align: center;
        top: 74px;
        width: 246px;
    }

    .mobile-counter .div {
        height: 28px;
        left: 43px;
        position: absolute;
        top: 168px;
        width: 234px;
    }

    .mobile-counter .overlap-group {
        height: 28px;
        left: 0;
        position: absolute;
        top: 0;
        width: 75px;
    }

    .mobile-counter .group-2 {
        height: 28px;
        left: 0;
        position: absolute;
        top: 0;
        width: 75px;
    }

    .mobile-counter .element {
        color: #ffffff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 24px;
        font-weight: 600;
        height: 28px;
        left: 0;
        letter-spacing: -0.24px;
        line-height: 27.8px;
        position: absolute;
        text-align: center;
        top: 0;
        white-space: nowrap;
        width: 38px;
    }

    .mobile-counter .d-as {
        color: #ffffff;
        font-family: "Montserrat", Helvetica;
        font-size: 12px;
        font-weight: 400;
        height: 14px;
        left: 38px;
        letter-spacing: -0.12px;
        line-height: 13.9px;
        position: absolute;
        top: 9px;
        white-space: nowrap;
        width: 33px;
    }

    .mobile-counter .ellipse {
        background-color: #ffffff;
        border-radius: 1px;
        height: 2px;
        left: 72px;
        position: absolute;
        top: 8px;
        width: 2px;
    }

    .mobile-counter .group-3 {
        height: 28px;
        left: 81px;
        position: absolute;
        top: 0;
        width: 79px;
    }

    .mobile-counter .text-wrapper {
        color: #ffffff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 24px;
        font-weight: 600;
        height: 28px;
        left: 0;
        letter-spacing: -0.24px;
        line-height: 27.8px;
        position: absolute;
        text-align: center;
        top: 0;
        white-space: nowrap;
        width: 38px;
    }

    .mobile-counter .horas {
        color: #ffffff;
        font-family: "Montserrat", Helvetica;
        font-size: 12px;
        font-weight: 400;
        height: 14px;
        left: 38px;
        letter-spacing: -0.12px;
        line-height: 13.9px;
        position: absolute;
        top: 9px;
        white-space: nowrap;
        width: 37px;
    }

    .mobile-counter .group-4 {
        height: 28px;
        left: 170px;
        position: absolute;
        top: 0;
        width: 68px;
    }

    .mobile-counter .element-2 {
        color: #ffffff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 24px;
        font-weight: 600;
        height: 28px;
        left: 0;
        letter-spacing: -0.24px;
        line-height: 27.8px;
        position: absolute;
        text-align: center;
        top: 0;
        white-space: nowrap;
        width: 38px;
    }

    .mobile-counter .min {
        color: #ffffff;
        font-family: "Montserrat", Helvetica;
        font-size: 12px;
        font-weight: 400;
        height: 14px;
        left: 38px;
        letter-spacing: -0.12px;
        line-height: 13.9px;
        position: absolute;
        top: 9px;
        white-space: nowrap;
        width: 26px;
    }

    .mobile-counter .ellipse-2 {
        background-color: #ffffff;
        border-radius: 1px;
        height: 2px;
        left: 164px;
        position: absolute;
        top: 9px;
        width: 2px;
    }

    .mobile-counter .vector {
        height: 1px;
        left: 1px;
        position: absolute;
        top: 144px;
        width: 312px;
    }

    .mobile-counter .img {
        height: 1px;
        left: 0;
        position: absolute;
        top: 221px;
        width: 312px;
    }

    .mobile-counter .logo-suerte-viajera-wrapper {
        background-color: #ffffff;
        border: 0.5px solid;
        border-color: #dde1e7;
        border-radius: 16px;
        box-shadow: 0px 4px 4px #415a7e40;
        height: 59px;
        left: 71px;
        position: absolute;
        top: 0;
        width: 169px;
    }

    .mobile-counter .logo-suerte-viajera {
        height: 50px;
        left: 8px;
        object-fit: cover;
        position: absolute;
        top: 6px;
        width: 152px;
    }

    .mobile-counter .group-5 {
        height: 25px;
        left: 8px;
        position: absolute;
        top: 226px;
        width: 299px;
    }

}

@media(min-width: 768px) {
    .timer-tablet {
        height: 211px;
        width: 706px;
        margin: auto;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .timer-tablet .overlap-group {
        height: 211px;
        position: relative;
    }

    .timer-tablet .rectangle {
        background-color: #004AAD;
        border-radius: 16px;
        height: 180px;
        left: 0;
        position: absolute;
        top: 31px;
        width: 706px;
    }

    .timer-tablet .element {
        color: #fff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 32px;
        font-weight: 600;
        left: 399px;
        letter-spacing: -0.32px;
        line-height: 37.1px;
        position: absolute;
        text-align: center;
        top: 87px;
        white-space: nowrap;
        width: 45px;
    }

    .timer-tablet .d-as {
        color: #fff;
        font-family: "Montserrat", Helvetica;
        font-size: 12px;
        font-weight: 400;
        left: 444px;
        letter-spacing: -0.12px;
        line-height: 13.9px;
        position: absolute;
        top: 97px;
        white-space: nowrap;
        width: 39px;
    }

    .timer-tablet .text-wrapper {
        color: #fff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 32px;
        font-weight: 600;
        left: 494px;
        letter-spacing: -0.32px;
        line-height: 37.1px;
        position: absolute;
        text-align: center;
        top: 87px;
        white-space: nowrap;
        width: 45px;
    }

    .timer-tablet .horas {
        color: #fff;
        font-family: "Montserrat", Helvetica;
        font-size: 12px;
        font-weight: 400;
        left: 542px;
        letter-spacing: -0.12px;
        line-height: 13.9px;
        position: absolute;
        top: 97px;
        white-space: nowrap;
        width: 43px;
    }

    .timer-tablet .div {
        color: #fff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 32px;
        font-weight: 600;
        left: 599px;
        letter-spacing: -0.32px;
        line-height: 37.1px;
        position: absolute;
        text-align: center;
        top: 87px;
        white-space: nowrap;
        width: 45px;
    }

    .timer-tablet .min {
        color: #fff;
        font-family: "Montserrat", Helvetica;
        font-size: 12px;
        font-weight: 400;
        left: 637px;
        letter-spacing: -0.12px;
        line-height: 13.9px;
        position: absolute;
        top: 97px;
        white-space: nowrap;
        width: 31px;
    }

    .timer-tablet .ellipse {
        background-color: #fff;
        border-radius: 1.17px;
        height: 2px;
        left: 481px;
        position: absolute;
        top: 105px;
        width: 2px;
    }

    .timer-tablet .ellipse-2 {
        background-color: #fff;
        border-radius: 1.17px;
        height: 2px;
        left: 589px;
        position: absolute;
        top: 106px;
        width: 2px;
    }

    .timer-tablet .group {
        height: 26px;
        left: 9px;
        position: absolute;
        top: 170px;
        width: 693px;
    }

    .timer-tablet .sortearemos-nuestro {
        color: #fff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 24px;
        font-weight: 600;
        left: 74px;
        letter-spacing: -0.24px;
        line-height: 27.8px;
        position: absolute;
        top: 77px;
        width: 294px;
    }

    .timer-tablet .mask-group {
        height: 76px;
        left: 0;
        position: absolute;
        top: 31px;
        width: 68px;
    }

    .timer-tablet .logo-suerte-viajera-wrapper {
        background-color: #ffffff;
        border: 0.5px solid;
        border-color: #dde1e7;
        border-radius: 16px;
        box-shadow: 0px 4px 4px #415a7e40;
        height: 57px;
        left: 272px;
        position: absolute;
        top: 0;
        width: 163px;
    }

    .timer-tablet .logo-suerte-viajera {
        height: 48px;
        left: 7px;
        object-fit: cover;
        position: absolute;
        top: 6px;
        width: 147px;
    }

    .timer-tablet .vector {
        height: 1px;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 160px;
        width: 706px;
    }
}


@media(min-width: 1440px) {
    .desktop-counter {
        height: 325px;
        width: 1196px;
        margin: auto;
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .desktop-counter .overlap {
        height: 325px;
        position: relative;
    }

    .desktop-counter .rectangle {
        background-color: #004AAD;
        border-radius: 16px;
        height: 246px;
        left: 0;
        position: absolute;
        top: 79px;
        width: 1196px;
    }

    .desktop-counter .group {
        height: 44px;
        left: 790px;
        position: absolute;
        top: 160px;
        width: 356px;
    }

    .desktop-counter .overlap-group {
        height: 44px;
        left: 0;
        position: absolute;
        top: 0;
        width: 115px;
    }

    .desktop-counter .div {
        height: 44px;
        left: 0;
        position: absolute;
        top: 0;
        width: 114px;
    }

    .desktop-counter .element {
        color: #fff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 40px;
        font-weight: 600;
        height: 44px;
        left: 0;
        letter-spacing: -0.4px;
        line-height: 46.4px;
        position: absolute;
        text-align: center;
        top: 0;
        white-space: nowrap;
        width: 59px;
    }

    .desktop-counter .d-as {
        color: #fff;
        font-family: "Montserrat", Helvetica;
        font-size: 16px;
        font-weight: 400;
        height: 22px;
        left: 59px;
        letter-spacing: -0.16px;
        line-height: 18.6px;
        position: absolute;
        top: 14px;
        white-space: nowrap;
        width: 51px;
    }

    .desktop-counter .ellipse {
        background-color: #fff;
        border-radius: 1.56px;
        height: 3px;
        left: 112px;
        position: absolute;
        top: 12px;
        width: 3px;
    }

    .desktop-counter .group-2 {
        height: 44px;
        left: 126px;
        position: absolute;
        top: 0;
        width: 126px;
    }

    .desktop-counter .horas {
        color: #fff;
        font-family: "Montserrat", Helvetica;
        font-size: 16px;
        font-weight: 400;
        height: 22px;
        left: 64px;
        letter-spacing: -0.16px;
        line-height: 18.6px;
        position: absolute;
        top: 14px;
        white-space: nowrap;
        width: 58px;
    }

    .desktop-counter .overlap-group-wrapper {
        height: 44px;
        left: 264px;
        position: absolute;
        top: 0;
        width: 96px;
    }

    .desktop-counter .overlap-group-2 {
        height: 44px;
        position: relative;
        width: 92px;
    }

    .desktop-counter .min {
        color: #fff;
        font-family: "Montserrat", Helvetica;
        font-size: 16px;
        font-weight: 400;
        height: 22px;
        left: 52px;
        letter-spacing: -0.16px;
        line-height: 18.6px;
        position: absolute;
        top: 14px;
        white-space: nowrap;
        width: 40px;
    }

    .desktop-counter .ellipse-2 {
        background-color: #fff;
        border-radius: 1.56px;
        height: 3px;
        left: 255px;
        position: absolute;
        top: 14px;
        width: 3px;
    }

    .desktop-counter .img {
        height: 45px;
        left: 15px;
        position: absolute;
        top: 264px;
        width: 1174px;
    }

    .desktop-counter .sortearemos-nuestro {
        color: #fff;
        font-family: "Montserrat Alternates", Helvetica;
        font-size: 32px;
        font-weight: 600;
        height: 47px;
        left: 125px;
        letter-spacing: -0.32px;
        line-height: 37.1px;
        position: absolute;
        top: 159px;
        width: 635px;
    }

    .desktop-counter .mask-group {
        height: 128px;
        left: 0;
        position: absolute;
        top: 79px;
        width: 116px;
    }

    .desktop-counter .logo-suerte-viajera-wrapper {
        background-color: #ffffff;
        border: 0.5px solid;
        border-color: #dde1e7;
        border-radius: 16px;
        box-shadow: 0px 4px 4px #415a7e40;
        height: 120px;
        left: 425px;
        position: absolute;
        top: 0;
        width: 345px;
    }

    .desktop-counter .logo-suerte-viajera {
        height: 102px;
        left: 16px;
        object-fit: cover;
        position: absolute;
        top: 14px;
        width: 310px;
    }

    .desktop-counter .vector {
        height: 1px;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 247px;
        width: 1196px;
    }
}